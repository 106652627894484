import React from 'react'
import Navigation from '../components/Navigation.jsx'
import ShopGridElement from '../components/ShopGridElement.jsx'
import Shoe from '../assets/render/MainRender.png'
import Footer from '../components/Footer.jsx'
import { Carousel, Row, Container, Col } from 'react-bootstrap'

export default function Shop() {
    return (
        <>
            <Navigation />
            <div>
                <Container id="spacer"></Container>
                <p className='ms-5 mb-5 display-3 fw-bolder'>Die Besten der Besten</p>
                <Container id="spacer"></Container>
                <div className='d-flex justify-content-center'>
                    <Carousel data-bs-theme="dark" className='w-75'>
                        <Carousel.Item >
                            <img src={Shoe} width="65%" style={{ marginLeft: '8rem' }} />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img src={Shoe} width="65%" style={{ marginLeft: '8rem' }} />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img src={Shoe} width="65%" style={{ marginLeft: '8rem' }} />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img src={Shoe} width="65%" style={{ marginLeft: '8rem' }} />
                        </Carousel.Item>
                    </Carousel>
                </div>
                <Container id="spacer"></Container>
                <p className='ms-4 display-5 fw-bold'>Kategorien</p>
                <Row className='ms-4 pb-3 justify-start w-1/2'>
                    <Col xs={2}>Herren</Col>
                    <Col xs={2}>Damen</Col>
                    <Col xs={2}>Kinder</Col>
                    <Col xs={2}>Wanderschuhe</Col>
                </Row>
                <Container fluid className='border-top border-secondary border-5 mb-5'></Container>
                <Container fluid className='m-2'>
                    <Row className='row-cols-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-5 row-cols-xxl-6'>
                        <Col>
                            <ShopGridElement title='Velocity' category='Herrenschuhe' price='89.99€' />
                        </Col>
                        <Col>
                            <ShopGridElement title='Quantum Soar' category='Wanderschuhe' price='109.99€' />
                        </Col>
                        <Col>
                            <ShopGridElement title='Echo Mirage' category='Frauenschuhe' price='94.99€' oldprice='114.99€' />
                        </Col>
                        <Col>
                            <ShopGridElement title='Velocity' category='Kinderschuhe' price='49.99€' oldprice='59.99€' />
                        </Col>
                        <Col>
                            <ShopGridElement title='Quantum Soar' category='Kinderschuhe' price='64.99€' oldprice='74.99€' />
                        </Col>
                        <Col>
                            <ShopGridElement title='Echo Mirage' category='Kinderschuhe' price='54.99€' />
                        </Col>
                        <Col>
                            <ShopGridElement title='Velocity' category='Herrenschuhe' price='99.99€' />
                        </Col>
                        <Col>
                            <ShopGridElement title='Quantum Soar' category='Frauenschuhe' price='79.99€' oldprice='99.99€' />
                        </Col>
                        <Col>
                            <ShopGridElement title='Echo Mirage' category='Frauenschuhe' price='89.99€' />
                        </Col>
                        <Col>
                            <ShopGridElement title='Velocity' category='Kinderschuhe' price='59.99€' oldprice='69.99€' />
                        </Col>
                        <Col>
                            <ShopGridElement title='Velocity' category='Herrenschuhe' price='89.99€' />
                        </Col>
                        <Col>
                            <ShopGridElement title='Quantum Soar' category='Wanderschuhe' price='109.99€' />
                        </Col>
                        <Col>
                            <ShopGridElement title='Echo Mirage' category='Frauenschuhe' price='94.99€' oldprice='114.99€' />
                        </Col>
                        <Col>
                            <ShopGridElement title='Velocity' category='Kinderschuhe' price='49.99€' oldprice='59.99€' />
                        </Col>
                        <Col>
                            <ShopGridElement title='Quantum Soar' category='Kinderschuhe' price='64.99€' oldprice='74.99€' />
                        </Col>
                        <Col>
                            <ShopGridElement title='Echo Mirage' category='Kinderschuhe' price='54.99€' />
                        </Col>
                        <Col>
                            <ShopGridElement title='Velocity' category='Herrenschuhe' price='99.99€' />
                        </Col>
                        <Col>
                            <ShopGridElement title='Quantum Soar' category='Frauenschuhe' price='79.99€' oldprice='99.99€' />
                        </Col>
                        <Col>
                            <ShopGridElement title='Echo Mirage' category='Frauenschuhe' price='89.99€' />
                        </Col>
                        <Col>
                            <ShopGridElement title='Velocity' category='Herrenschuhe' price='89.99€' />
                        </Col>
                        <Col>
                            <ShopGridElement title='Quantum Soar' category='Wanderschuhe' price='109.99€' />
                        </Col>
                        <Col>
                            <ShopGridElement title='Echo Mirage' category='Frauenschuhe' price='94.99€' oldprice='114.99€' />
                        </Col>
                        <Col>
                            <ShopGridElement title='Velocity' category='Kinderschuhe' price='49.99€' oldprice='59.99€' />
                        </Col>
                        <Col>
                            <ShopGridElement title='Quantum Soar' category='Kinderschuhe' price='64.99€' oldprice='74.99€' />
                        </Col>
                        <Col>
                            <ShopGridElement title='Echo Mirage' category='Kinderschuhe' price='54.99€' />
                        </Col>
                        <Col>
                            <ShopGridElement title='Velocity' category='Herrenschuhe' price='99.99€' />
                        </Col>
                        <Col>
                            <ShopGridElement title='Quantum Soar' category='Frauenschuhe' price='79.99€' oldprice='99.99€' />
                        </Col>
                        <Col>
                            <ShopGridElement title='Echo Mirage' category='Frauenschuhe' price='89.99€' />
                        </Col>
                    </Row>
                </Container>
                <Container id="spacer"></Container>
                <Footer />
            </div>
        </>
    )
}
